import InventoryCategory from '@/domain/InventoryCategory';
import ItemType from '@/domain/ItemType';
import Item from '@/domain/Item';
import LocationType from '@/domain/LocationType';
import Location from '@/domain/Location';

export default class CycleCountConfiguration {
    public id!: number;

    public description!: string;

    public cronSchedule!: string;

    public allItems: boolean = false;

    public allLocations: boolean = false;

    public allInventoryCategories: boolean = false;

    public countItemsUnderLoad: boolean = false;

    public canCountInventoryCategories?: Array<InventoryCategory>;

    public itemTypes?: Array<ItemType>;

    public items?: Array<Item>;

    public locationTypes?: Array<LocationType>;

    public locations?: Array<Location>;

    public oneTimeDueDate?: Date;

    constructor(init?: CycleCountConfiguration) {
        this.id = init?.id ?? 0;
        this.description = init?.description ?? '';
        this.cronSchedule = init?.cronSchedule ?? '';
        this.allItems = init?.allItems ?? false;
        this.allLocations = init?.allLocations ?? false;
        this.allInventoryCategories = init?.allInventoryCategories ?? false;
        this.canCountInventoryCategories = init?.canCountInventoryCategories ?? [];
        this.itemTypes = init?.itemTypes ?? [];
        this.items = init?.items ?? [];
        this.locationTypes = init?.locationTypes ?? [];
        this.locations = init?.locations ?? [];
        this.oneTimeDueDate = init?.oneTimeDueDate ?? new Date();
        this.countItemsUnderLoad = init?.countItemsUnderLoad ?? false;
    }
}

import CycleCountConfiguration from '@/domain/cyclecount/CycleCountConfiguration';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import CycleCountConfigurationApiService from '@/services/api/CycleCountConfigurationApiService';

export default class CycleCountConfigurationService {
    private cycleCountConfigurationApiService: CycleCountConfigurationApiService;

    constructor() {
        this.cycleCountConfigurationApiService = new CycleCountConfigurationApiService();
    }

    public async addRecurringCycleCountConfiguration(dto: CycleCountConfiguration): Promise<DataAccessResponse<number>> {
        const response = await this.cycleCountConfigurationApiService.addRecurringCycleCountConfiguration(dto);
        return response;
    }

    public async getCycleCountConfigurations() : Promise<DataAccessResponse<Array<CycleCountConfiguration>>> {
        const response = await this.cycleCountConfigurationApiService.getCycleCountConfigurations();
        return response;
    }

    public async updateCycleCountConfiguration(dto: CycleCountConfiguration) : Promise<DataAccessResponse<CycleCountConfiguration>> {
        const response = await this.cycleCountConfigurationApiService.updateCycleCountConfiguration(dto);
        return response;
    }
}

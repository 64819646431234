

import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import BRow from '@/components/bootstrap-library/BRow.vue';
import OrbisCard from '@/components/OrbisCard.vue';
import BButtonGroup from '@/components/bootstrap-library/BButtonGroup.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import CycleCountConfiguration from '@/domain/cyclecount/CycleCountConfiguration';
import coreStore from '@/store/CoreStore';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import ItemService from '@/services/ItemService';
import Item from '@/domain/Item';
import ItemType from '@/domain/ItemType';
import ItemTypeService from '@/services/ItemTypeService';
import useValidator from '@/validation/useValidator';
import CycleCountReportService from '@/services/CycleCountReportService';
import { useNotification } from '@/composable/useNotifications';
import DateInput from '@/components/inputs/DateInput.vue';
import RecurrenceDialog from '@/components/RecurrenceDialog.vue';
import CycleCountConfigurationService from '@/services/CycleCountConfigurationService';
import { TableDefinition } from '@/types';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import Permissions from '@/services/permissions/Permissions';

enum LocationButtonGroupEnum {
  Location = 'location',
  LocationType = 'location-type',
}

enum ItemButtonGroupEnum {
  Item = 'item',
  ItemType = 'item-type',
}

enum ScheduleButtonGroupEnum {
  OneTime = 'one-time',
  Recurring = 'recurring',
}

type State = {
    showCreateCycleCount: boolean;
    description: string;
    locationGroupSelection: LocationButtonGroupEnum;
    itemGroupSelection: ItemButtonGroupEnum;
    scheduleGroupSelection: ScheduleButtonGroupEnum;
    cycleCountConfiguration: CycleCountConfiguration;
    items: Array<Item>;
    itemTypes: Array<ItemType>;
    saving: boolean;
    loading: boolean;
    cycleCountConfigurationList: Array<CycleCountConfiguration>;
}

export default defineComponent({
    name: 'cycle-count-manager',
    components: {
        BDropdownItem,
        BDropdown,
        RecurrenceDialog,
        DateInput,
        FormMultiSelect,
        BFormCheckbox,
        BCol,
        BButtonGroup,
        OrbisCard,
        BRow,
        BFormInput,
        BModal,
        BButton,
        Screen,
    },
    setup() {
        const itemService = new ItemService();
        const itemTypeService = new ItemTypeService();

        const { inventoryCategoryStore } = coreStore.getInstance();
        const { locationStore, configStore } = masterDataStore.getInstance();

        const maxCharacterLength = 75;

        const state = reactive<State>({
            showCreateCycleCount: false,
            description: '',
            locationGroupSelection: LocationButtonGroupEnum.Location,
            itemGroupSelection: ItemButtonGroupEnum.Item,
            scheduleGroupSelection: ScheduleButtonGroupEnum.OneTime,
            cycleCountConfiguration: new CycleCountConfiguration(),
            items: [],
            itemTypes: [],
            saving: false,
            loading: false,
            cycleCountConfigurationList: [],
        });

        function categoryDisplayInTable(row: { readonly item: CycleCountConfiguration}) {
            const display = row.item.allInventoryCategories ? 'All' : row.item.canCountInventoryCategories?.map((inventoryCategory) => inventoryCategory.description).join(', ') ?? '';
            return display.length > maxCharacterLength ? `${display.substr(0, maxCharacterLength - 3)}...` : display;
        }

        function itemDisplayInTable(row: { readonly item: CycleCountConfiguration}) {
            let display = '';
            if (row.item.allItems) {
                return 'All';
            }

            if (row.item.items && row.item.items.length > 0) {
                display = row.item.items.map((item) => item.name).join(', ');
                return display.length > maxCharacterLength ? `${display.substr(0, maxCharacterLength - 3)}...` : display;
            }

            display = row.item.itemTypes?.map((itemType) => itemType.description).join(', ') ?? '';
            return display.length > maxCharacterLength ? `${display.substr(0, maxCharacterLength - 3)}...` : display;
        }

        function locationDisplayInTable(row: { readonly item: CycleCountConfiguration }) {
            let display = '';
            if (row.item.allLocations) {
                return 'All';
            }

            if (row.item.locations && row.item.locations.length > 0) {
                display = row.item.locations.map((location) => location.name).join(', ');
                return display.length > maxCharacterLength ? `${display.substr(0, maxCharacterLength - 3)}...` : display;
            }

            display = row.item.locationTypes?.map((locationType) => locationType.description).join(', ') ?? '';
            return display.length > maxCharacterLength ? `${display.substr(0, maxCharacterLength - 3)}...` : display;
        }

        function scheduleDisplayInTable(row: { readonly item: CycleCountConfiguration }) {
            if (row.item.cronSchedule === '0 0 0 * * *') {
                return 'Daily';
            }

            const cronSplit = row.item.cronSchedule.split(' ');

            if (cronSplit.length === 6
                && (cronSplit[5].includes('SUN')
                    || cronSplit[5].includes('MON')
                    || cronSplit[5].includes('TUE')
                    || cronSplit[5].includes('WED')
                    || cronSplit[5].includes('THU')
                    || cronSplit[5].includes('FRI')
                    || cronSplit[5].includes('SAT'))) {
                return 'Weekly';
            }

            if (cronSplit.length === 6 && (cronSplit[3].match('^[0-9]+$') || cronSplit[5].includes('#'))) {
                return 'Monthly';
            }

            return '';
        }

        const allConfigurationsTable = computed(
            (): TableDefinition<CycleCountConfiguration> => ({
                items: state.cycleCountConfigurationList,
                key: 'masterDataCycleCountConfigurationList',
                name: getTitleCaseTranslation('core.domain.cycleCountManager'),
                columnDefinition: [
                    {
                        key: 'description',
                        label: getTitleCaseTranslation('core.domain.description'),
                    },
                    {
                        key: 'locations',
                        label: getTitleCaseTranslation('core.domain.locations'),
                    },
                    {
                        key: 'items',
                        label: getTitleCaseTranslation('core.domain.items'),
                    },
                    {
                        key: 'canCountInventoryCategories',
                        label: getTitleCaseTranslation('core.domain.categories'),
                    },
                    {
                        key: 'cronSchedule',
                        label: getTitleCaseTranslation('core.domain.schedule'),
                    },
                ],
            }),
        );

        const notification = useNotification();

        const { validateForm, validationResult, clearResults } = useValidator<CycleCountConfiguration>('cycle-count-configuration');

        const cycleCountReportService = new CycleCountReportService();
        const cycleCountConfigurationService = new CycleCountConfigurationService();

        async function setItems() {
            const response = await itemService.getAllItems();

            if (response.success) {
                state.items = response.items.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        async function setItemTypes() {
            state.itemTypes = await itemTypeService.getItemTypes();
        }

        onBeforeMount(async () => {
            await Promise.all([setItems(), setItemTypes()]);
        });

        function resetModal() {
            state.locationGroupSelection = LocationButtonGroupEnum.Location;
            state.itemGroupSelection = ItemButtonGroupEnum.Item;
            state.scheduleGroupSelection = ScheduleButtonGroupEnum.OneTime;
            state.cycleCountConfiguration = new CycleCountConfiguration();
        }

        function createNewCycleCountConfiguration() {
            state.showCreateCycleCount = true;
            clearResults();
        }

        function closeCreateCycleCount() {
            state.showCreateCycleCount = false;
            resetModal();
            clearResults();
        }

        function handleLocationGroupClick(selected: LocationButtonGroupEnum) {
            state.locationGroupSelection = selected;
        }

        function handleItemGroupClick(selected: ItemButtonGroupEnum) {
            state.itemGroupSelection = selected;
        }

        function handleScheduleGroupClick(selected: ScheduleButtonGroupEnum) {
            state.scheduleGroupSelection = selected;
        }

        async function saveCycleCountConfiguration() {
            if (state.cycleCountConfiguration.allLocations) {
                state.cycleCountConfiguration.locations = [];
                state.cycleCountConfiguration.locationTypes = [];
            }

            if (state.cycleCountConfiguration.allItems) {
                state.cycleCountConfiguration.items = [];
                state.cycleCountConfiguration.itemTypes = [];
            }

            if (state.cycleCountConfiguration.allInventoryCategories) {
                state.cycleCountConfiguration.canCountInventoryCategories = [];
            }

            if (state.locationGroupSelection === LocationButtonGroupEnum.Location) {
                state.cycleCountConfiguration.locationTypes = [];
            } else if (state.locationGroupSelection === LocationButtonGroupEnum.LocationType) {
                state.cycleCountConfiguration.locations = [];
            }

            if (state.itemGroupSelection === ItemButtonGroupEnum.Item) {
                state.cycleCountConfiguration.itemTypes = [];
            } else if (state.itemGroupSelection === ItemButtonGroupEnum.ItemType) {
                state.cycleCountConfiguration.items = [];
            }

            validateForm(state.cycleCountConfiguration);

            if (validationResult.isValid) {
                let result = null;
                state.saving = true;
                if (state.scheduleGroupSelection === ScheduleButtonGroupEnum.OneTime) {
                    state.cycleCountConfiguration.cronSchedule = '';
                    const newReportResult = await cycleCountReportService.addOneTimeCycleCountReport(state.cycleCountConfiguration);
                    if (newReportResult) {
                        notification.showSuccess(getTranslation('core.validation.cycleCountReportCreated'));
                        closeCreateCycleCount();
                    }
                } else if (state.cycleCountConfiguration.id === 0 && state.scheduleGroupSelection === ScheduleButtonGroupEnum.Recurring) {
                    const newConfigResult = await cycleCountConfigurationService.addRecurringCycleCountConfiguration(state.cycleCountConfiguration);
                    if (newConfigResult && newConfigResult.success) {
                        notification.showSuccess(getTranslation('core.validation.cycleCountConfigurationSuccess'));
                        state.cycleCountConfiguration.id = newConfigResult.data;
                        state.cycleCountConfigurationList.unshift(state.cycleCountConfiguration);
                        closeCreateCycleCount();
                    }
                } else if (state.cycleCountConfiguration.id > 0) {
                    const updatedConfigResult = await cycleCountConfigurationService.updateCycleCountConfiguration(state.cycleCountConfiguration);
                    if (updatedConfigResult && updatedConfigResult.success) {
                        notification.showSuccess(getTranslation('core.validation.cycleCountConfigurationUpdated'));
                        state.cycleCountConfigurationList = state.cycleCountConfigurationList.map((config) => {
                            if (config.id === state.cycleCountConfiguration.id) {
                                return state.cycleCountConfiguration;
                            }
                            return config;
                        });
                        closeCreateCycleCount();
                    }
                }
                state.saving = false;
            }
        }

        function updateCycleCountCronExpression(value: string) {
            state.cycleCountConfiguration.cronSchedule = value;
        }

        async function getCycleCountConfigurations() {
            state.loading = true;
            const response = await cycleCountConfigurationService.getCycleCountConfigurations();
            if (response.success) {
                state.cycleCountConfigurationList = response.data;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            await getCycleCountConfigurations();
        });

        function openEdit(cycleCountConfiguration: CycleCountConfiguration) {
            resetModal();
            state.cycleCountConfiguration = new CycleCountConfiguration(cycleCountConfiguration);
            state.scheduleGroupSelection = ScheduleButtonGroupEnum.Recurring;

            if (cycleCountConfiguration.items && cycleCountConfiguration.items.length > 0) {
                state.itemGroupSelection = ItemButtonGroupEnum.Item;
            } else if (cycleCountConfiguration.itemTypes && cycleCountConfiguration.itemTypes.length > 0) {
                state.itemGroupSelection = ItemButtonGroupEnum.ItemType;
            }

            if (cycleCountConfiguration.locations && cycleCountConfiguration.locations.length > 0) {
                state.locationGroupSelection = LocationButtonGroupEnum.Location;
            } else if (cycleCountConfiguration.locationTypes && cycleCountConfiguration.locationTypes.length > 0) {
                state.locationGroupSelection = LocationButtonGroupEnum.LocationType;
            }

            state.showCreateCycleCount = true;
        }

        return {
            state,
            createNewCycleCountConfiguration,
            closeCreateCycleCount,
            LocationButtonGroupEnum,
            ItemButtonGroupEnum,
            ScheduleButtonGroupEnum,
            handleLocationGroupClick,
            handleItemGroupClick,
            handleScheduleGroupClick,
            inventoryCategoryStore,
            locationStore,
            configStore,
            saveCycleCountConfiguration,
            validationResult,
            updateCycleCountCronExpression,
            allConfigurationsTable,
            Permissions,
            openEdit,
            categoryDisplayInTable,
            itemDisplayInTable,
            locationDisplayInTable,
            scheduleDisplayInTable,
        };
    },
    methods: { getTitleCaseTranslation },
});



import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';

const maxRecurringNumber = 10;

enum RecurrenceTypeEnum {
    Daily,
    Weeks,
    Months,
}

enum DaysOfWeekEnum {
    SUN = 'Su',
    MON = 'M',
    TUE = 'Tu',
    WED = 'W',
    THU = 'Th',
    FRI = 'F',
    SAT = 'Sa',
}

enum FullDaysOfWeekEnum {
    SUN = 'Sunday',
    MON = 'Monday',
    TUE = 'Tuesday',
    WED = 'Wednesday',
    THU = 'Thursday',
    FRI = 'Friday',
    SAT = 'Saturday',
}

enum MonthOptionEnum {
    DAYOFMONTH = 'DAYOFMONTH',
    DAYOFWEEK = 'DAYOFWEEK',
}

enum MonthWeekdayOrdinalEnum {
    FIRST = '1st',
    SECOND = '2nd',
    THIRD = '3rd',
    FOURTH = '4th',
    LAST = 'Last',
}

type State = {
    recurrenceType: RecurrenceTypeEnum;
    recurrenceNumber: number;
    recurrenceDays: Array<string>;
    recurrenceMonthDay: number;
    monthRecurrenceType: string;
    monthRecurrenceWeekdayOrdinal: number;
    monthRecurrenceDayOfWeek: number;
}

export default defineComponent({
    name: 'recurrence-dialog',
    components: {
        BButton,
        BCol,
        BFormSelect,
    },
    props: {
        modelValue: { type: String, required: true },
        error: { type: String, default: () => '' },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const state = reactive<State>({
            recurrenceType: RecurrenceTypeEnum.Daily,
            recurrenceNumber: 1,
            recurrenceDays: [],
            recurrenceMonthDay: 1,
            monthRecurrenceType: MonthOptionEnum.DAYOFMONTH.toString(),
            monthRecurrenceWeekdayOrdinal: 0,
            monthRecurrenceDayOfWeek: 0,
        });

        const isDayOfMonthSelected = computed(() => state.monthRecurrenceType === MonthOptionEnum.DAYOFMONTH);

        const recurrenceTypeEnumArray = Object.values(RecurrenceTypeEnum)
            .filter((value) => typeof value === 'string')
            .map((label, index) => ({ label, value: index }));

        const monthRecurrenceWeekdayOrdinalEnumArray = Object.values(MonthWeekdayOrdinalEnum)
            .filter((value) => typeof value === 'string')
            .map((label, index) => ({ label, value: index }));

        const longDaysOfWeekEnumArray = Object.values(FullDaysOfWeekEnum)
            .filter((value) => typeof value === 'string')
            .map((label, index) => ({ label, value: index }));

        const shortDaysOfWeekEnumArray = Object.entries(DaysOfWeekEnum).map(([key, value]) => ({ key, value }));

        function generateCronExpression() {
            let expression = '0 0 0';
            const recurrenceNumberExpression = state.recurrenceNumber === 1 ? '' : `/${state.recurrenceNumber}`;
            if (state.recurrenceType === RecurrenceTypeEnum.Weeks) {
                expression += ` *${recurrenceNumberExpression} * ${state.recurrenceDays.join(',')}`;
            } else if (state.recurrenceType === RecurrenceTypeEnum.Months) {
                if (state.monthRecurrenceType === MonthOptionEnum.DAYOFMONTH) {
                    expression += ` ${state.recurrenceMonthDay} *${recurrenceNumberExpression} *`;
                } else if (state.monthRecurrenceType === MonthOptionEnum.DAYOFWEEK) {
                    expression += ` * *${recurrenceNumberExpression} ${state.monthRecurrenceDayOfWeek}#${state.monthRecurrenceWeekdayOrdinal === 4 ? 'L' : (state.monthRecurrenceWeekdayOrdinal + 1)}`;
                }
            } else if (state.recurrenceType === RecurrenceTypeEnum.Daily) {
                expression += ' * * *';
            }
            context.emit('update:modelValue', expression);
        }

        function setRecurrenceNumber(value: number) {
            state.recurrenceNumber = value;
            generateCronExpression();
        }

        function setRecurrenceType(value: RecurrenceTypeEnum) {
            state.recurrenceType = value;
            generateCronExpression();
        }

        function setRecurrenceMonthDay(value: number) {
            state.recurrenceMonthDay = value;
            generateCronExpression();
        }

        function getDayOfWeekNum(day: string) {
            switch (day) {
            case 'SUN':
                return 0;
            case 'MON':
                return 1;
            case 'TUE':
                return 2;
            case 'WED':
                return 3;
            case 'THU':
                return 4;
            case 'FRI':
                return 5;
            case 'SAT':
                return 6;
            default:
                return -1;
            }
        }

        function handleDayClick(day: string) {
            if (state.recurrenceDays.find((x) => x === day)) {
                state.recurrenceDays = state.recurrenceDays.filter((x) => x !== day);
            } else {
                state.recurrenceDays.push(day);
                state.recurrenceDays = state.recurrenceDays.sort((day1, day2) => {
                    const day1Value = getDayOfWeekNum(day1);
                    const day2Value = getDayOfWeekNum(day2);
                    if (day1Value < day2Value) {
                        return -1;
                    }
                    if (day1Value > day2Value) {
                        return 1;
                    }
                    return 0;
                });
            }
            generateCronExpression();
        }

        function setMonthRecurrenceWeekdayOrdinal(value: number) {
            state.monthRecurrenceWeekdayOrdinal = value;
            generateCronExpression();
        }

        function setMonthRecurrenceDayOfWeek(value: number) {
            state.monthRecurrenceDayOfWeek = value;
            generateCronExpression();
        }

        function setMonthRecurrenceType(value: string) {
            state.monthRecurrenceType = value;
            generateCronExpression();
        }

        function loadRecurrenceNumber(index: number, expressionSplit: Array<string>) {
            if (index > expressionSplit.length) {
                state.recurrenceNumber = 1;
                return;
            }
            if (expressionSplit[index].includes('/')) {
                const recurrenceNumberSplit = expressionSplit[index].split('/');
                state.recurrenceNumber = parseInt(recurrenceNumberSplit[1], 10);
            } else {
                state.recurrenceNumber = 1;
            }
        }

        function loadCronExpression() {
            if (props.modelValue === '0 0 0 * * *') {
                state.recurrenceType = RecurrenceTypeEnum.Daily;
            }

            const cronSplit = props.modelValue.split(' ');

            if (cronSplit.length === 6
                && (cronSplit[5].includes('SUN')
                    || cronSplit[5].includes('MON')
                    || cronSplit[5].includes('TUE')
                    || cronSplit[5].includes('WED')
                    || cronSplit[5].includes('THU')
                    || cronSplit[5].includes('FRI')
                    || cronSplit[5].includes('SAT'))) {
                state.recurrenceType = RecurrenceTypeEnum.Weeks;
                state.recurrenceDays = cronSplit[5].split(',');
                loadRecurrenceNumber(3, cronSplit);
            }

            if (cronSplit.length === 6 && (cronSplit[3].match('^[0-9]+$') || cronSplit[5].includes('#'))) {
                state.recurrenceType = RecurrenceTypeEnum.Months;
                loadRecurrenceNumber(4, cronSplit);
                if (cronSplit[3].match('^[0-9]+$')) {
                    state.monthRecurrenceType = MonthOptionEnum.DAYOFMONTH;
                    state.recurrenceMonthDay = parseInt(cronSplit[3], 10);
                } else if (cronSplit[5].includes('#')) {
                    state.monthRecurrenceType = MonthOptionEnum.DAYOFWEEK;
                    const dayOfWeekExpressionSplit = cronSplit[5].split('#');
                    state.monthRecurrenceDayOfWeek = parseInt(dayOfWeekExpressionSplit[0], 10);
                    if (dayOfWeekExpressionSplit[1] === 'L') {
                        state.monthRecurrenceWeekdayOrdinal = 4;
                    } else {
                        state.monthRecurrenceWeekdayOrdinal = (parseInt(dayOfWeekExpressionSplit[1], 10) - 1);
                    }
                }
            }
        }

        onBeforeMount(() => {
            if (props.modelValue) {
                loadCronExpression();
            } else {
                generateCronExpression();
            }
        });

        return {
            getTitleCaseTranslation,
            setRecurrenceType,
            setRecurrenceNumber,
            recurrenceTypeEnumArray,
            maxRecurringNumber,
            state,
            RecurrenceTypeEnum,
            DaysOfWeekEnum,
            shortDaysOfWeekEnumArray,
            handleDayClick,
            setRecurrenceMonthDay,
            MonthOptionEnum,
            MonthWeekdayOrdinalEnum,
            monthRecurrenceWeekdayOrdinalEnumArray,
            setMonthRecurrenceWeekdayOrdinal,
            longDaysOfWeekEnumArray,
            setMonthRecurrenceDayOfWeek,
            setMonthRecurrenceType,
            isDayOfMonthSelected,
        };
    },
});

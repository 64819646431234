import axios, { AxiosResponse } from 'axios';
import CycleCountConfiguration from '@/domain/cyclecount/CycleCountConfiguration';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class CycleCountConfigurationApiService {
    public async addRecurringCycleCountConfiguration(dto: CycleCountConfiguration): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/cyclecountconfiguration/recurring`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getCycleCountConfigurations(): Promise<DataAccessResponse<Array<CycleCountConfiguration>>> {
        const response: AxiosResponse<DataAccessResponse<Array<CycleCountConfiguration>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountconfiguration`,
        });

        return new DataAccessResponseClass<Array<CycleCountConfiguration>>(response).response;
    }

    public async updateCycleCountConfiguration(dto: CycleCountConfiguration): Promise<DataAccessResponse<CycleCountConfiguration>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountConfiguration>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/cyclecountconfiguration`,
            data: dto,
        });

        return new DataAccessResponseClass<CycleCountConfiguration>(response).response;
    }
}
